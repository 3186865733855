<template>
  <div class="course flex">
    <div class="dmest text-left">
      <div class="chd flex row-between">
        <div class="search flex">
          <input type="text" placeholder="请输入课程名称搜索" class="searchInput" v-model="word" @keydown.enter="$throttle(onsearch, 1000)" />
          <div class="searchIcon" @click="onsearch"><img src="../assets/img/search.png" ></div>
          <div class="del" v-if="showDel" @click="onClear"><img src="../assets/img/img28.png" ></div>
        </div>
        <div class="them f14 c333 flex-1 text-right" v-if="subtitle">本周主题 <span>{{subtitle}}</span></div>
      </div>
      <div class="layTitle f18 c333" v-if="init == 0 || init == 1">今日课程</div>
      <div class="hd flex row-between" v-if="init == 2">
        <div class="back flex f20 cup" @click="close">
          <img src="@/assets/img/back.png" >
          <span>后退</span>
        </div>
        
        <div class="searched f16 c333">共查询到<span>{{length}}</span>个结果</div>
      </div>
      <!-- <div class="listBox" :class="{cals:init == 0 || init == 1}">
        
      </div> -->
      <div class="course-list" ref="container">
        <div class="noMsg" v-if="list.length==0 && noMsgShow">
          <div class="img"><img src="../assets/img/courseMsg.jpg" ></div>
          <div class="text-center f24 c333 pt10" v-if="init == 0">课程为空</div>
          <div class="text-center f24 c333 pt10" v-else>查询无结果</div>
        </div>
        <div class="item flex col-top mb30" v-for="(item, index) in list" :key="index">
          <div class="seter" :style="{backgroundColor: item.hue}">
            <div class="hn flex">
              <div class="litle"><img :src="item.fbIcon" ></div>
              <div class="font f14">{{item.sujectName}}</div>
            </div>
            <div class="soat flex">
              <img src="../assets/img/img10.png" class="icon cup imgWidth" @click="goHas(item, 'video')">
              <!-- <img src="../assets/img/img32.png" class="icon cup imgWidth" v-else> -->

              <img src="../assets/img/img11.png" class="icon cup imgWidth" v-if="item.hasCases" @click="goHas(item, 'cases')">
              <img src="../assets/img/img29.png" class="icon cup imgWidth" v-else>
            </div>
          </div>
          <div class="nnst pl25">
            <div class="nTitle f14 pt25">{{item.name}}</div>
            <div class="dest f12 pt15">{{item.abstract}}</div>
          </div>
          <img src="../assets/img/img9.png" class="tap-go cup imgWidth" v-if="item.hasReading" @click="goHas(item, 'reading')">
          <img src="../assets/img/img19.png" class="tap-go cup imgWidth" v-else>
        </div>
      </div>
    </div>
    <div class="dsearth pt40 text-left">
      <div class="pl20">
        <user bg-color="#F1F1F1"></user>
      </div>
      <div class="layTitle f18 c333 pt20 pl20 pb10">课程查找</div>
      <div class="pl20 pr35">
        <div class="layBox plr10">
          <div class="flex pt10">
            <div class="icon"><img src="../assets/img/img12.png" ></div>
            <el-dropdown placement="bottom-start" @command="classAnd">
              <div class="text flex row-between lh15 plr15 cup">
                <div class="msg f14 c666" :class="{c999: !tapData.classId}">{{tapData.className}}</div>
                <div><i class="el-icon-caret-bottom"></i></div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,index) in tapData.classList" :key="index" :command="item">{{item.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="flex pt10">
            <div class="icon"><img src="../assets/img/img13.png" ></div>
            <el-dropdown placement="bottom-start" @command="brothAnd">
              <div class="text flex row-between lh15 plr15 cup">
                <div class="msg f14 c666" :class="{c999: !tapData.brothId}">{{tapData.brothName}}</div>
                <div><i class="el-icon-caret-bottom"></i></div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,index) in tapData.brother" :key="index" :command="item">{{item.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="plr50" v-if="tapData.childList.length">
        <div class="cls">
          <div class="f14 c333 text-center ptb10">周</div>
          <div class="flex row-between plr35">
            <div class="action mr10 cup" @click="pre"><img src="../assets/img/img14.png" ></div>
            <div class="cstion flex-1 text-center">{{tapData.childName}}</div>
            <div class="action ml10 cup" @click="next"><img src="../assets/img/img15.png" ></div>
          </div>
          <div class="f14 c333 text-center ptb10">日</div>
          <div class="fbnt pb10">
            <span class="item cup" :class="{active: tapData.cateId==item.id}" v-for="(item, index) in tapData.lastList" :key="index" @click="tapLast(item)">{{item.name}}</span>
          </div>
        </div>
      </div>
      <div class="plr20 mt10">
        <div class="btnOk f13 cup" @click="$throttle(keyUp, 1000)">OK</div>
      </div>
    </div>
    <modal ref="modal"></modal>
  </div>
</template>

<script>
  import User from '../components/user.vue'
  import modal from '../components/modal.vue'
  import { getItem, setItem } from '../assets/js/storage.js'
  import { mapState } from 'vuex';
  import { Scroll } from '@/utils/loading'
export default {
  name: 'course',
  components:{
    User,
    modal
  },
  data(){
    return{
      word: '',
      noMsgShow: false,
      showDel: false,
      page: 1,
      length: 0,
      loading: false,
      list: [],
      init: 0,
      subtitle: '',
      subtitle2: '',
      tapData: {
        classList: [],
        className: '年龄',
        classId: '',
        brother: [],
        brothName: '学年',
        brothId: '',
        childList: [],
        childName: '',
        childId: '',
        childIndex: 0,
        lastList: [],
        cateId: '',
      },
    }
  },
  mounted() {
    if(getItem('classfit') && this.user){
      this.tapData = getItem('classfit');
      this.subtitle = getItem('subtitle');
      this.subtitle2 = getItem('subtitle');
      this.getData();
    }
    // else{
    //   localStorage.removeItem("classfit");
    // }
    this.classfit();
    this.$bus.$on('keyWord', value => {
      this.word = value;
      this.onsearch();
    })
    // 下拉加载 
    this.$scroll(this.$refs.container, () => {
      if (this.length > this.list.length) {
        !this.loading && this.getData()
      }
    })
    Scroll.listenTouchDirection()
  },
  computed: {
    ...mapState({
      user: state => state.stateUser
    })
  },
  watch:{
    'word'(oldVal){
      if(oldVal){
        this.showDel = true;
      }else{
        this.showDel = false;
      }
    }
  },
  methods:{
    async keyUp(){
      if(!this.user) return this.$bus.$emit('isLogin');
      if(!this.tapData.cateId) return this.$message.warning('请选择分类');
      this.subtitle = this.subtitle2;
      setItem('subtitle', this.subtitle);
      setItem('classfit', this.tapData);
      this.word = '';
      this.list = [];
      this.page = 1;
      this.getData();
    },
    onsearch(){
      if(!this.word) return this.$message.warning('关键词不能为空');
      this.list = [];
      this.page = 1;
      this.length = 0;
      this.tapData.childList = [];
      this.tapData.cateId = '';
      this.tapData.className = '年龄';
      this.tapData.classId = '';
      this.tapData.brothName = '学年';
      this.tapData.brothId = '';
      this.tapData.childName = '';
      this.tapData.childId = '';
      this.tapData.childIndex = 0;
      this.tapData.cateId = '';
      this.subtitle = '';
      this.getData();
    },
    async getData(){
      this.loading = true;
      var params = {
        page: this.page,
        pagesize: 10,
        name: this.word,
        cateId: this.tapData.cateId
      }
      this.noMsgShow = false;
      var res = await this.$api.get('/api/project_course/todayList', params);
      if(res.code == 0){
        var data = res.data.list;
        if(data && data.length){
          this.length = res.data.count;
          this.list = [...this.list, ...data];
          this.page++;
        }
      }
      if(this.word){
        this.init = 2;
      }else{
        this.init = 1;
      }
      this.loading = false;
      this.page = this.page + 1;
      this.noMsgShow = true;
    },
    async classfit(){
      var res = await this.$api.get('/api/project_course/getCateTree');
      if(res.code == 0){
        var data = res.data.list;
        this.tapData.classList = data;

        // 进入页面后如果没有缓存，设置默认选择，如果不需要，去掉下面的代码即可
        if(!getItem('classfit')){
          this.classAnd(data[0]);
          this.brothAnd(data[0].child[0]);
          this.tapLast(data[0].child[0].child[0].child[0]);
          this.keyUp();
        }
      }
    },
    classAnd(item){
      this.tapData.className = item.name;
      this.tapData.classId = item.id;
      this.tapData.brother = item.child;
      this.tapData.brothId = '';
      this.tapData.brothName = '学年';
      this.tapData.childId = '';
      this.tapData.childList = [];
      this.tapData.cateId = '';
      this.lastList = [];
    },
    brothAnd(item){
      this.tapData.brothId = item.id;
      this.tapData.brothName = item.name;
      this.tapData.childList = item.child;
      if(item.child.length){
        this.subtitle2 = item.child[0].subtitle;
        this.tapData.childName = item.child[0].name;
        this.tapData.childId = item.child[0].id;
        this.tapData.childIndex = 0;
        this.tapData.lastList = item.child[0].child;
      }
      this.tapData.cateId = '';
    },
    next(){
      var index = this.tapData.childIndex + 1;
      if(this.tapData.childList[index]){
        // console.log(this.tapData.childList[index]);
        this.subtitle2 = this.tapData.childList[index].subtitle;
        this.tapData.childName = this.tapData.childList[index].name;
        this.tapData.childId = this.tapData.childList[index].id;
        this.tapData.lastList = this.tapData.childList[index].child;
        this.tapData.childIndex = index;
        this.tapData.cateId = '';
      }else{
        return;
      }
    },
    pre(){
      var index = this.tapData.childIndex - 1;
      if(this.tapData.childList[index]){
        // console.log(this.tapData.childList[index]);
        this.subtitle2 = this.tapData.childList[index].subtitle;
        this.tapData.childName = this.tapData.childList[index].name;
        this.tapData.childId = this.tapData.childList[index].id;
        this.tapData.lastList = this.tapData.childList[index].child;
        this.tapData.childIndex = index;
        this.tapData.cateId = '';
      }else{
        return;
      }
    },
    tapLast(item){
      this.tapData.cateId = item.id;
    },
    close(){
      this.list = [];
      this.word = '';
      this.tapData.cateId = '';
      this.init = 0;
    },
    async goHas(item, type){
      if(!this.user) return this.$bus.$emit('isLogin');
      var res = await this.$api.get("/api/project_course/checkCourseCanView", {id: item.id, checkCan: true});
      if(res.code == 0){
        if(type == 'video'){
          this.$router.push({name: "videoPpt", query: {id: item.id}});
        }else{
          this.$router.push('/course/detail?type='+type+'&id='+item.id);
        }
      }else{
        this.$refs.modal.showHand();
        return;
      }
    },
    onClear(){
      this.word = '';
    }
  },
  
}
</script>

<style lang="scss" scoped>
  .course{
    width: 100%;
    height: 100%;
    .imgWidth{
      width: 36px;
    }
    .dmest{
      width: 71%;
      height: 100%;
      min-width: 800px;
      padding-top: 50px;
      padding-left: 40px;
      .hd{
        padding-top: 40px;
        padding-bottom: 30px;
        .searched{
          font-weight: bold;
          span{
            color: red;
          }
        }
        .back{
          color: #6672fb;
          span{
            font-weight: bold;
          }
          img{
            width: 22px;
            height: 22px;
            vertical-align: middle;
            margin-right: 15px;
          }
        }
      }
      .listBox{
        height: 100%;
        overflow:hidden;
        position: relative;
        overflow-y: auto;
        &.cals{
          max-height: calc(100% - 150px);
        }
      }
      .course-list{
        height: calc(100% - 140px);
        position: relative;
        overflow: auto;
        .item{
          overflow: hidden;
          position: relative;
          width: 100%;
          height: 186px;
          background-color: #f6f9fd;
          border-radius: 20px;
          .tap-go{
            position: absolute;
            right: 36px;
            top: 18px;
          }
          .nnst{
            width: 560px;
            .dest{
              color: #888888;
              line-height: 20px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
              overflow: hidden;
              white-space: pre-wrap;
              width: 80%;
            }
            .nTitle{
              color: #5b68fb;
              font-weight: bold;
              width: 80%;
              display:-webkit-box;
              overflow:hidden;
              text-overflow:ellipsis;
              -webkit-line-clamp:2;
              -webkit-box-orient:vertical;
              line-height: 1.4;
            }
          }
          .seter{
            position: relative;
            width: 334px;
            height: 100%;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            .soat{
              width: 180px;
              margin: 0 auto;
              padding-top: 75px;
              .icon{
                &:first-child{
                  margin-right: 98px;
                }
              }
            }
            .hn{
              position: absolute;
              left: 14px;
              top: 11px;
              .font{
                font-weight: bold;
                color: #FFFFFF;
                padding-left: 10px;
              }
              .litle{
                position: relative;
                width: 28px;
                height: 28px;
                border-radius: 6px;
                background-color: #FFFFFF;
                img{
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  max-width: 28px;
                  min-height: 28px;
                }
              }
            }
          }
        }
        
      }
      .layTitle{
        font-weight: bold;
        padding-left: 10px;
        padding-top: 30px;
        padding-bottom: 20px;
      }
      .chd{
        .search{
          position: relative;
          overflow: hidden;
          width: 360px;
          height: 40px;
          background-color: #ecf1ff;
          border-radius: 6px;
          .del{
            position: absolute;
            top: 50%;
            right: 86px;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
            cursor: pointer;
            font-size: 0;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .searchIcon{
            position: relative;
            width: 67px;
            background-color: #d9e2fc;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            height: 50px;
            img{
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 18px;
              height: 18px;
            }
          }
          .searchInput{
            background-color: inherit;
            border: 0;
            flex: 1;
            padding: 0 15px;
            font-size: 12px;
            color: #000034;
            &:-moz-placeholder{
              color: #95c2ea;
            }
            &::-moz-placeholder{
              color: #95c2ea;
            }
            &::-webkit-input-placeholder{
              color: #95c2ea;
            }
            &:-ms-input-placeholder{
              color: #95c2ea;
            }
          }
        }
        .them{
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 50px;
          span{
            font-weight: 400;
            font-size: 26px;
            color: #5b68fb;
            padding-left: 2px;
            max-width: 74%;
          }
        }
      }
    }
    .dsearth{
      width: 29%;
      height: 100%;
      min-width: 304px;
      background-color: #f6f9fd;
      margin-left: 8px;
      .btnOk{
        text-align: center;
        width: 100%;
        height: 30px;
        line-height: 30px;
        border-radius: 30px;
        background-color: #ff6d6d;
        color: #FFFFFF;
      }
      .cls{
        background-color: #FFFFFF;
        width: 100%;
        .fbnt{
          text-align: center;
          .item{
            overflow: hidden;
            width: 40px;
            height: 40px;
            border-radius: 40px;
            line-height: 40px;
            background-color: #d8dffd;
            display: inline-block;
            margin-right: 5px;
            color: #FFFFFF;
            font-size: 20px;
            &.active{
              background-color: #6672fb;
            }
            &:last-child{
              margin-right: 0;
            }
          }
        }
        .action{
          width: 30px;
          height: 30px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .cstion{
          background-color: #f6f9fd;
          height: 28px;
          line-height: 28px;
          border-radius: 28px;
        }
      }
      .layBox{
        background-color: #6672fb;
        width: 100%;
        height: 110px;
        border-radius: 10px;
        .text{
          flex: 1;
          height: 40px;
          line-height: 40px;
          background-color: #FFFFFF;
          border-radius: 8px;
        }
        /deep/ .el-dropdown{
          flex: 1;
          min-width: 160px;
        }
        .icon{
          width: 40px;
          height: 40px;
          border-radius: 8px;
          background-color: #FFFFFF;
          position: relative;
          margin-right: 13px;
          img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .layTitle{
        font-weight: bold;
      }
    }
  }
</style>